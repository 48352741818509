































import { Dictionary } from "@/types/helpers";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ConfirmModal extends Vue {
  dialog = false;
  resolve: ((value: boolean | PromiseLike<boolean>) => void) | undefined;
  reject: ((value: boolean | PromiseLike<boolean>) => void) | undefined;
  message = "";
  title = "";
  options = {
    color: "",
    width: 400,
    zIndex: 200,
    noconfirm: false,
  };

  open(
    title: string = this.$t("global.dialog.confirm.title").toString(),
    message: string = this.$t("global.dialog.confirm.message").toString(),
    options: Dictionary<string | number> = {}
  ): Promise<boolean> {
    this.dialog = true;
    this.title = title;
    this.message = message;
    this.options = Object.assign(this.options, options);
    return new Promise<boolean>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  agree(): void {
    if (!this.resolve) return;
    this.resolve(true);
    this.dialog = false;
  }

  cancel(): void {
    if (!this.resolve) return;
    this.dialog = false;
  }
}
