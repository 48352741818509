











































import { Component, Vue, Watch, Ref } from "vue-property-decorator";
import MarkdownItVue from "markdown-it-vue";
import subjectModule from "@/store/modules/subjects";
import { Subject } from "@/types/subject";
import ConfirmModal from "@/components/utility/ConfirmModal.vue";

@Component({
  components: {
    MarkdownItVue,
    ConfirmModal,
  },
})
export default class SubjectDisplay extends Vue {
  @Ref() readonly confirm!: ConfirmModal;

  get subject(): Subject | undefined {
    return subjectModule.getCurrentSubject;
  }

  mounted(): void {
    this.loadSubject();
  }

  @Watch("$route.params.subject_id")
  updateSubject(): void {
    this.loadSubject();
  }

  async loadSubject(): Promise<void> {
    try {
      const subjectId = this.$route.params.subject_id;
      await subjectModule.selectSubject(subjectId);
    } catch (err) {
      if (err.response.status == 404) {
        this.$router.replace({ name: "NotFound" });
      }
    }
  }

  async remove(): Promise<void> {
    const reply = await this.confirm.open();
    if (reply) {
      if (!this.subject) return;
      try {
        await subjectModule.delete(this.subject);
        this.$router.push({ name: "subjects" });
        this.$toast.success(this.$t("global.success").toString());
      } catch (err) {
        this.$toast.error(err.response.data);
      }
    }
  }
}
